<aside id="left-panel">

  <nav>
    <!-- NOTE: Notice the gaps after each icon usage <i></i>..
    Please note that these links work a bit different than
    traditional href="" links. See documentation for details.
    -->
    <!-- <br><div class="profile-image-box">
        <div class="gold" [ngClass]="imageData=='' || imageData==undefined?'setnavBackgroundImg':''" (click)="goToProfile()">
           <img *ngIf="imageData!='' && imageData!=undefined" [src]="imageData ? imageData:''" alt="Image"  class="body-image profile-pic-image">
        </div>
    </div>
    <div class="text-center"><label class="userlabel" (click)="goToProfile()">{{userName}}</label></div>
    <div class="text-center mb-4"><label class="company-label" id="help_text"  style="cursor:default">{{companyName}}</label></div> -->
        <!-- <ul saSmartMenu class="navList">
            <li routerLinkActive="active" >
                <a title="{{'routerCompanyProfile' | i18n}}" routerLink="/companyDetails">
                    <i class="fas fa-building"></i>
                    <span class="menu-item-parent pl-3"> {{'routerCompanyProfile' | i18n}} <div *ngIf="companyPendingCount>0" class="pending-badge">{{companyPendingCount}}</div></span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a title="{{'account_userManagemet' | i18n}}" routerLink="/userManagement">
                    <i class="fas fa-user-edit"></i>
                    <span class="menu-item-parent pl-3"> {{'account_userManagemet' | i18n}}</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a title="{{'account_companyManagemet' | i18n}}" routerLink="/companyManagement">
                    <i class="fas fa-building"></i>
                    <span class="menu-item-parent pl-3"> {{'account_companyManagemet' | i18n}}</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/billingOverview" title="{{'common_billing_overView' | i18n}}">
                    <i class="fas fa-file-invoice"></i> 
                    <span class="menu-item-parent pl-3">{{'common_billing_overView' | i18n}} <div *ngIf="pendingCount>0" class="pending-badge">{{pendingCount}}</div></span>
                   
                </a>
            </li>
            <li routerLinkActive="active" class="manageSubscription">
                <a routerLink="/manageSubscription" title="{{'common_manage_subscription' | i18n}}">
                    <i class="fas fa-list"></i>
                    <span class="menu-item-parent pl-3">{{'common_manage_subscription' | i18n}}</span>
                </a>
            </li>
        </ul> -->
        <ul saSmartMenu *ngIf="!isNavLoading" class="navList">    
            <ng-container  *ngFor="let node of navigationList">
                <li *ngIf="userPermissions.indexOf(node.RIGHTS)!=-1" routerLinkActive="active" [ngClass]="node.LABEL" class="{{node.CLASS}}">
                    <a *ngIf="node.children.length > 0" href="#" title="{{node.LABEL |i18n}}" >
                      <i class="fa fa-lg fa-fw {{node.ICON}}"></i>
                      <span class="menu-item-parent menuTooltip">{{node.LABEL |i18n}}</span>
                    </a>
                    <a *ngIf="node.children.length === 0" routerLink="{{node.MODULE}}" title="{{node.LABEL | i18n}}">
                      <i class="fa fa-lg fa-fw {{node.ICON}}"></i>
                      <span class="menu-item-parent menuTooltip">{{node.LABEL |i18n}}</span>
                    </a>
                    <ul *ngIf="node.children.length > 0">
                      <li *ngFor="let child of node.children" routerLinkActive="active"  class="{{child.CLASS}}">
                        <a class="child-menu-tooltip" *ngIf="child.children.length === 0"  routerLink="{{child.MODULE}}" title="{{child.LABEL |i18n}}">{{child.LABEL |i18n}}</a>                       
                      </li>
                    </ul>
                  </li>
            </ng-container>          
                <!-- <li *ngIf="userPermissions.indexOf('mtc.comparefile.')!=-1" routerLinkActive="active" [ngClass]="file-compare">                  
                <a  [href]="fileCompareUrl" title="{{'common_file_compare' |i18n}}" target="_blank" >
                  <i class="fa fa-lg fa-fw fa-file"></i>
                  <span class="menu-item-parent menuTooltip">{{'common_file_compare' |i18n}}</span>
                </a>
                
              </li> -->
          </ul>
    </nav>

    <div class="navFooter">
        <div class="colDisplay">
            <!-- <div class="helpSection footerTextColor">
                <div>
                    <i class="fa fa-question-circle" aria-hidden="true" style="font-size: 20x;"></i>
                    <span id="help_text" title="{{'common_help' | i18n}}" style="font-size: 14px;padding-left: 10px; cursor: pointer;">{{'common_help' | i18n}}</span>
                </div>
            </div> -->
            <div class="logoutSection">
                <sa-clearCache></sa-clearCache>
            </div>
        </div>
    </div>
</aside>
