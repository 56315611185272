export const environment = {
  production: true,
  linkdInUr_AccessToken:'https://www.linkedin.com/oauth/v2/',
  linkdInApi:'https://api.linkedin.com/v2/',
  firebase: {
  },
  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  API_END_POINT_ADMIN : 'https://fkbwl.api.io-market.com/api/v1/',
  API_END_POINT_BUSINESS : 'https://api-business.io-procurement.com/api/v1/',
  API_END_POINT_ACCOUNT : 'https://fjy29.api.io-market.com/api/v1/',
  LOGIN_URL:"https://accounts.io-market.com/#/auth/login/",
  ADMIN_URL:"https://admin.io-market.com/#/",
  allowedRedirectPath:['business.io-market.com','contract.netgate.io-market.com','gate2b.io-market.com','fkbwl.api.io-market.com','dhdgu.api.io-market.com','fjy29.api.io-market.com','proffix.io-market.com','pxbkd.api.io-market.com','acbkd.api.io-market.com','accounting.io-market.com','crm.io-market.com'],
  profile_URL :"https://accounts.io-market.com/#/profile",
  privateKey:{key:"MIIEowIBAAKCAQEAzheqwMQrSFIFkQtsiPFKUDHNSqlae2Hfkfr/7J3cF0hrSfcBHcT/63c3gGt02J0dZE+WWcJ0qt4jNZflxUnzuYpPJw+tv+9Kenw/ghHNKtfiaB20SPl6naOKusPrTbWTjC7MnL3jbs9wBtZxC14vufQ5E1TyHKKxuQtW93ej9OclK/e3aoCv68yhQbHEzS2W8mcp8YfzeyF+BkoMGfPYUgeuV+XLSPrgoK6PBPZlAZe7gSlCwo+bbwg3ShafZcRUMg2ParSP3y1DMaoyxmRERWEChyzbBSYzARrzoV75I4zkzySbnY16VV7Rxtmxv08EGJM89auNx09lpO+fJr30WQIDAQABAoIBAF3ZgTSPIHaqg0ZtwVsLJiAmzam2PTH6e+jqOCj0KgmgPNtN1ABOeYwsmeWtCWSrB3tqkrkFSEOXLMl3Bnc1Gfvf8/OBvEmvGmd0tSbyHE/qR3vKKxMgKKook0BBf6G/khVAKoUmI6+OXyF9/bSDi5BOmr8m66oGnI/7jfrbTJp05qO5VQZDVoc5DxFqDgDNr8pqCZhMaYWXD02Oxs+iiHoiFWGth+wjQVvfmd15Yqvwpb09TMB3YVkQ+cnfI9i07SZZ0/o2b8iEFBQTbhNfHDm8QMRJ5k6lcjbREFIWuh0jI6ti9PFFVYnyyTuRioM15rmmGb9iktmW5V9As2WeZgECgYEA72rLgHNHnIjbI5WzioeztRPpyLM09McjuWrxYOh3UFLJvs2mR8KNJ+7ifhqkZRhLP0T/jnNSiWN67mjKANGClJX1ODnZalXAoz4fGH5tUR7VClCFuRUI3bSYSFSkMwmoBipKWykkrf5TcgO9KO43T0gpRs/VihlbUO2EMdH2NLECgYEA3F36SOFCpFTp0doHolB+KopgP8T7Cc2MbbliuAoejOXdE+mcG4MJ7hdRP9g1y0zPBvD1tHdHfFbP2/wFKupfaYca5N0dWefRL/2inz/bXdpWFCN76Wv7bQGLocSxD9aZEXAgjcHU2ys5vHyTtzMm7JMXezIvevIdrPk/AUN4xCkCgYAJ78TCcAZo2MU/lNb+yOMMgOcTDV0FS76P04BxJTTOgKEe6iockFaLcb60vSZVi8yTs6lnoCGs0hQoEPFRV2C0CgVDcCM15pk4LTvdKGIhG8Z7xxvFsrnLedNYLSsjcyMmHls3rV14WzXbZYxURo7FG5ma9sVGFgdUNIdoCe7OAQKBgHjXSAJjFEpNDhM3fmwxu53UV8dgW1Ea2GDrAglt8ybMfhkHR8WGbBGV08Yw5/7d1cE2rWYS6q5yZHV7k9HeyAd0QPkzj37Xskygx2aQe9jRe9Z7Rt3PiwZRjKJe28GVqkZ3uEJEAldVMsn7fn09naCpYHz8HjFLR56s5zHoL/dRAoGBAIlchmCpCCZeWNxZ+EbGtqJHPCC7tm2symaMydadmNNYvWKE1AznO6if7LrdrmZU0tqJG0ut2bYAinsLei/gUQyOWSUgm6bMQ4lpsBfkCNAxztDviOOwi3Av5aTs6Ic00eMWUHWSwQJIMrPmzQBxzxy9itB6x14UdAw5ivJfkf2D"},
  encryptionKey : "it6Hig8a56YacWPPuduLbA==",
  cookieDomain:".io-market.com",
  checkCookie:"expireCookie",
  tokenExpireTime:"cookieCreatedTime",

   //LinkdIn
   client_id:"787c2dgbaj6gvq",
  scope:"r_liteprofile%20r_emailaddress",
  redirect_uri:"https://accounts.io-market.com/#/auth/socialLogin",
  grant_type:"authorization_code",
  client_secret:"v90nhsmJVKvpEIuJ",


  // Google
  gClient_id:'997002379881-h07i7fj6mh24gu969clovbvli92b4q31.apps.googleusercontent.com',
  cookiepolicy: 'single_host_origin',
  gScope:'profile email',
  stripePK:"pk_test_51JPR33HCmdVWPntTtDn8z71SB3EJOhgvYMM33olpRJi1qtNW8T79BfFT63UT0C9esWVkANaEaLWB8ca15inthRN800MTvxgTol",
  LOGOUT_URL :"https://mtc.io-market.com/#/auth/logout/",
  domain:'.io-market.com',
  languageCookie:'languageCookie',
  styleCookie:'styleCookie',
  ACCOUNTS_URL:'https://accounts.io-market.com/#/',
  WC_APPS:"https://wc.io-market.com/subscribedApps/main.js",
  WC_PROFILE:"https://wc.io-market.com/profile/main.js",
  USER_ACTIVE_CHECK:"isUserActive",
  userInfo:"userInfo",
  BEXIO_URL: "https://bexio.io-market.com/#/",
  GATE2BURL:"https://gate2b.io-market.com/",
  API_END_POINT_GATE2B:"https://dhdgu.api.io-market.com/api/v1/gate2bAPI/gate2B",
  MTCURL: "https://mtc.io-market.com/#/",
  API_END_POINT_MTC : 'https://mqzpl.api.io-market.com/api/v1/gate2bAPI',
  mtc_cookie: "mtc_cookie",
  permissions: 'permissions',
  EBILL_MAIL:"ebillgateway@io-market.com",
  API_SIX_END_POINT: 'https://mqzpl.api.io-market.com/api/v1/sixAPICertificate',
  FORMATS:{'CXMLWithPDF':'.xml', "CXML1.2":'.xml', 'ETS3.1':'.xml', 'SAP02' :'.xml', 'OpenTrans2.1':'.xml','EDIFACTD.01B':'.edi','YellowBill2.0':'.xml','SignedYellowBill2.0':'.xml','Comarch1.0':'.xml','ETS2.0':'.xml','SAP05':'.xml','QR PDF':'.pdf','Meta Coded PDF':'.pdf' },
  EBILL_QR_INVOICE: "D_EBILL_QR_PDF_SPEZIFIKATION_DE_2022.PDF",
  color:"",
  XSD_API_HOST:"https://mqzpl.api.io-market.com/api/v1/gate2bAPI/",
  API_NODE_END_POINT:'https://mqzpl.api.io-market.com/api/v1/',
  tax:"0.081",
  fileGrabInterface:[{label: 'SFTP', value:'sftp'},{label: 'EMAIL', value:'email'},{label:'WEB SERVICE', value: 'ws'},{label:'AS2', value: 'as2'},{label:'OTHER', value: 'other'}],
  EBILL_ERROR_PRICE:"0.20",
  fileCompareUrl: "http://192.168.200.216:5005/file-compare.html",
  COMPARE_API: "https://dwncu.api.io-market.com/api/v1/main/",
  posfinance_id:"41100000007797776",
  interconnect_posfinance_receive_iomID:"41100000000023711"
};
