import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
// const cloneDeep = require('clone-deep');
@Injectable({
  providedIn: 'root'
})
export class BillerCertificateService {
  // private messageSector = new BehaviorSubject('');
  // private setSector = new BehaviorSubject('');
  // currentSector = this.messageSector.asObservable();
  // settSector = this.setSector.asObservable();
  @Output() messageSector: EventEmitter<any> = new EventEmitter();
  @Output() setSector: EventEmitter<any> = new EventEmitter();
  httpOptions = {
    headers: new HttpHeaders(),
  };
  gate2bUrl:string;
  mtcApiUrl:string;
  // formDataSelected;
  constructor(private httpClient: HttpClient) {
    this.gate2bUrl=environment.API_END_POINT_MTC+'/gate2B';
    this.mtcApiUrl =environment.API_NODE_END_POINT;
   
   }
  changeMessage(sector: any) {
    this.messageSector.emit(sector);
  }
  setMessage(sector: any) {
    this.setSector.emit(sector);
  }
  getSectorsData() {
    return this.httpClient.get(this.mtcApiUrl+'sixAPICertificate/getSectors');
  }
  createBiller(data){
    return this.httpClient.post(this.mtcApiUrl+'sixAPICertificate/createBiller',data);    
  }
  updateBiller(data){
    return this.httpClient.post(this.mtcApiUrl+'sixAPICertificate/updateBiller',data);
  }
  updateAsset(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/updateAsset',data);
    return this.httpClient.post(this.mtcApiUrl+'sixAPICertificate/updateAsset',data);
  }
  getBillerDetails(data){
    return this.httpClient.post(this.mtcApiUrl+'sixAPICertificate/getBillerDetails',data);
  }
  fetchAsset(data){
    return this.httpClient.post(this.mtcApiUrl+'sixAPICertificate/getAsset',data);
  }
  fetchBiller(data){
    // return this.httpClient.post('http://192.168.2.60:8080/api/v1/sixAPICertificate/getBiller',data);
    return this.httpClient.post(this.mtcApiUrl+'sixAPICertificate/getBiller',data);
  }
  getDocumentFormatList(data){
    return this.httpClient.post(this.gate2bUrl+'/post-list/document-format/',data);
  }
  downloadFile(data){
    return this.httpClient.post(environment.XSD_API_HOST+'downloadFile',data);
  }
  getDocumentTypeList(data){
    return this.httpClient.post(this.gate2bUrl+'/document-type', data);
  }
  getInterfaceList(netcom_id){
    return this.httpClient.post(this.gate2bUrl+'/netcomid-interface',netcom_id);
  }  
  getCompanyDetails(data){
    return this.httpClient.post(this.mtcApiUrl +`gate2bAPI/companyDetails`,data);
    // return this.httpClient.post(`http://192.168.2.55:5600/api/v1/gate2bAPI/companyDetails`,data);

  }
  getNetcomid(data){
    return this.httpClient.post(environment.API_END_POINT_MTC +`/getNetcomId`,data);

  }
  updateEbilleConnection(data){
    return this.httpClient.post(this.gate2bUrl +`/update-ebill-connection`,data);
  }
  searchBiller(data){
    return this.httpClient.post(this.mtcApiUrl+'sixAPICertificate/searchBiller',data);
    // return this.httpClient.post('http://192.168.2.60:8080/api/v1/sixAPICertificate/searchBiller',data);
  }
  activeBillers(data){
    return this.httpClient.post(this.gate2bUrl+`/activeBillers`,data);
    // return this.httpClient.post("http://192.168.2.55:5000/api/v1/gate2bAPI/activeBillers",data);   
  }
  // changePayementComments(data){
  //   return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/changePayementComments",data);
  //   // return this.httpClient.post("http://192.168.2.47:3000/api/v1/gate2bAPI/changePayementComments",data);
  
  // }
  // getPayementComments(data){
  //   return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/getPayementComments",data);
  //   //  return this.httpClient.post("http://192.168.2.47:3000/api/v1/gate2bAPI/getPayementComments",data);
    
  // }
}
