<header id="header" [ngClass]="headerColor ? 'colorChangeTest' : 'theme-bg-color' ">
    <div id="logo-group">
        <span id="logo"></span>
    </div>
    <div class="collapseMenu menu-left-item">
        <sa-collapse-menu></sa-collapse-menu>
    </div>
    <div class="maintenance">
        MAINTENANCE
    </div>
    <div class="pull-right">
        <div class="header-menu d-flex flex-row justfy-content-start flex-wrap flex-md-nowrap">
            
            
            <div class="menu-item menu-item_pading">
                <div class="text-white p-2">
                    <div class="billing-cycle model-7">
                        <span class="showDarkIcon" title="{{'header_dark' | i18n}}"><i class="fas fa-moon darkHeaderIcon"></i></span>
                        <!-- <span class="theme-header-text-color showDark">{{'header_dark' | i18n}}</span> -->
                        <div class="mx-3 checkbox mb-2">
                            <input type="checkbox" id="themeStyle" (click)="onSelectTheme($event)">
                            <label></label>
                        </div>
                        <span class="showLightIcon" title="{{'header_light' | i18n}}"><i class="fas fa-sun lightHeaderIcon"></i></span>
                        <!-- <span class="theme-header-text-color showLight">{{'header_light' | i18n}}</span> -->
                    </div>
                </div>
            </div>
            <div class="menu-item language-align "title="{{'common_language' | i18n}}">
                <sa-language-selector></sa-language-selector>
            </div>
            <div class="menu-item" title="{{'common_fullscreen' | i18n}}" [ngClass]="fullScreenSelected?'':'menuSelected'">
                <sa-full-screen (onFullScreenSelect)="selectFullScreen($event)"></sa-full-screen>
            </div>
            <!-- <div class="menu-item" [ngClass]="activitySelected?'menuSelected':''">
                <sa-activities (onActivitySelect)="selectActivity($event)"  [activityType]="'message'"></sa-activities>
            </div>
            <div class="menu-item"  [ngClass]="notificationSelected?'menuSelected':''">
                <sa-activities (onActivitySelect)="selectActivity($event)" [activityType]="'notification'"></sa-activities>                
            </div> -->
            <!-- <div class="menu-item no-hover">
                <img src="assets/img/iconSvg/39-Notification.svg" class="svgImageIcons" alt="">
            </div> -->
            <!-- <div class="menu-item webcomponent-option">
            <span  class="webComponetsBorder webComponetsHoverColor webItem">
                <div id="webComponetsOptions" style="padding: 6px 0px 0px 0px" ></div>
            </span>
            
        </div> -->
        <div class="menu-item webcomponent-profile" >
            <span  class="webComponetsHoverColor webprofileitem">
                <div class="">
                    <div class="avatar">
                        <div id="webComponetsProfile"></div>  
                    </div>
                </div>
            </span>
        </div>
        </div>
        <!-- <ul class="headerItems">
            <li class="ml-2 mr-2">
                <div class="text-white p-2">
                    <div class="billing-cycle model-7">
                        <span>Dark</span>
                        <div class="mx-3 checkbox mb-2">
                            <input type="checkbox" (click)="onSelectTheme($event)">
                            <label></label>
                        </div>
                        <span>Light</span>
                    </div>
                </div>
            </li>
            <li class="ml-2 mr-4 mb-2">
                <sa-language-selector></sa-language-selector>
            </li>
            <li class="ml-2 mr-4">
                <sa-full-screen></sa-full-screen>
            </li>
            <li class="ml-2 mr-4">
                <sa-activities></sa-activities>
            </li>
            <li class="ml-2 mr-4">
                <img src="assets/img/iconSvg/39-Notification.svg" class="svgImageIcons" alt="">
            </li>
            <li class="ml-2 mr-4">
                <div id="webComponetsOptions" style="padding: 9px 0px 0px 0px" ></div>
            </li>
            <li class="ml-2 profileIconHead">
                <div class="">
                    <div class="avatar">
                        <div id="webComponetsProfile">
                        </div>
                    </div>
                </div>
                <i class="fa fa-angle-down pull-right" style="color: white;"></i>
            </li>
        </ul> -->
        
    </div>
    <div *ngIf="showerrorAlert">
        <div style="display: none;" ><sa-errorAlert></sa-errorAlert></div>
    </div>
</header>
<div class="border-green"></div>
<div bsModal #newBuild="bs-modal" class="modal fade mdModal serviceModel" id="lgModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content interConnContent">
              <div class="crossDiv" >
                <span class="modal-title" id="" style="font-size: 16px;font-weight: bold;">{{'newversion_available' | i18n}}</span>
                  <div class="pull-right closeModal" (click)="newBuild.hide()">
                          <a >
                              <i class="fa fa-close crossBtn" aria-hidden="true"></i>
                          </a>
                      </div>
                  </div>
                  <div class="row  pl-4 pr-4 mr-0 mt-4">
                    <div class="pl-4" style="font-size: 15px" [innerHTML]="buildAlert"></div>                     
                    <div class="row w-100 mb-4 justify-content-end mt-4">
                        <button  class="btn-primary btnModalSave text-center floatRight" title="{{'common_clearcache_build' | i18n}}" (click)="clearCache()">{{'common_clearcache_build' | i18n}}</button>
                    </div>
              </div>     
             
          </div>
      </div>
  </div>
  <div bsModal #cookieError="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}" id="myModal">
  <div class="modal-dialog modal-sl modal-dialog-centered">
      <div class="modal-content modalBodyBgColor border" style="padding: 15px !important;  width: 800px;">
      <!-- <header class="">                                        
          <div class="pull-right crossDivError">
              <a (click)="cookieError.hide();" title="{{'common_close' | i18n}}">
                  <i class="fa fa-close crossBtn" aria-hidden="true"></i>
              </a>
          </div>
      </header> -->
          <div class="modal-body modalBody p-0">
              <div class="modal-body modalBody payBody">
                  <h4>{{'common_cookie_error'|i18n}}</h4>
              </div>
              <div class="MessageBoxButtonSection">
                
                <button id="bot2-Msg1" class="btn btn-default btn-sm botTempo" (click)="errorAlertOk()">{{'common_ok'|i18n}}</button>
              </div>
          </div>
      </div>
  </div>
</div>