import { Injectable, EventEmitter, Output } from '@angular/core';
// import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EmitService {

  private _emitter;
  private _emitter_removegrpmember;
  public previousUrl;
  //To readjust table
  private readjustDataTableSubject: BehaviorSubject<any>;
  private readjustDataTableEvent: any;
  refreshWizardEventEbill: any;
  //To refresh table
  private refreshDataTableSubject: BehaviorSubject<any>;
  private refreshDataTableEvent: any;
  @Output() langLoginChange: EventEmitter<any> = new EventEmitter();
  @Output() reloadDygraph: EventEmitter<any> = new EventEmitter();
  // @Output() selectedCompanyChange: EventEmitter<any> = new EventEmitter();
  private userLangChange = new BehaviorSubject<any>('');
  cast = this.userLangChange.asObservable();
  newDistSubject = new BehaviorSubject<any>(false);
  checkInactiveInterFaceSubject: BehaviorSubject<any>;
  checkInactiveInterfaceEvent: any;
  companysearchNetcomIdStatus: BehaviorSubject<any>;
  newDistEvent = this.newDistSubject.asObservable();
  refreshWizardDataSubjectEbill:  BehaviorSubject<any>;
  companysearchNetcomIdEvent: any;
  selectedCompanyIdEvent: any;
  selectCompanyIdEvent: any;
  refreshApiErroralert:  BehaviorSubject<any>;
  selectedCompanyId:  BehaviorSubject<any>;
  selectCompanyId:  BehaviorSubject<any>;
  refreshApiErrorEvent:any;
  companysearchIdStatus: BehaviorSubject<string>;
  companysearchIdEvent: any;
  constructor() {
    this._emitter = new EventEmitter ( );
    this._emitter_removegrpmember = new EventEmitter ( );

    this.readjustDataTableSubject = new BehaviorSubject<any>({});
    this.readjustDataTableEvent = this.readjustDataTableSubject.asObservable();

    this.refreshDataTableSubject = new BehaviorSubject<any>({});
    this.refreshDataTableEvent = this.refreshDataTableSubject.asObservable();

    this.refreshWizardDataSubjectEbill = new BehaviorSubject<any>(false);
    this.refreshWizardEventEbill = this.refreshWizardDataSubjectEbill.asObservable();

    this.checkInactiveInterFaceSubject = new BehaviorSubject<any>(false);
    this.checkInactiveInterfaceEvent = this.checkInactiveInterFaceSubject.asObservable();

    this.companysearchNetcomIdStatus = new BehaviorSubject<string>('');
    this.companysearchNetcomIdEvent = this.companysearchNetcomIdStatus.asObservable();

    this.refreshApiErroralert = new BehaviorSubject<any>(false);
    this.refreshApiErrorEvent = this.refreshApiErroralert.asObservable();

    this.selectedCompanyId = new BehaviorSubject<string>('');
    this.selectedCompanyIdEvent = this.selectedCompanyId.asObservable();
    
    this.selectCompanyId = new BehaviorSubject<string>('');
    this.selectCompanyIdEvent = this.selectCompanyId.asObservable();

    this.companysearchIdStatus = new BehaviorSubject<string>('');
    this.companysearchIdEvent = this.companysearchIdStatus.asObservable();

  }

  subscribeReadjustDataTable(next, err?, complete?){
    return this.readjustDataTableEvent.subscribe(next, err, complete);
  }

  publishReadjustDataTable(data){
    this.readjustDataTableSubject.next(data);
  }

  subscribeRefreshDataTable(next, err?, complete?){
    return this.refreshDataTableEvent.subscribe(next, err, complete);
  }

  publishRefreshDataTable(data){
    this.refreshDataTableSubject.next(data);
  }

  subscribeToServiceEmitter ( callback ) {
    return this._emitter.subscribe ( b => callback ( b ) );
  }

  removemeberfrmGrp ( callback ) {
    return this._emitter_removegrpmember.subscribe ( b => callback ( b ) );
  }
  
  removeMemberFrmGrp ( data ) {
    this._emitter_removegrpmember.emit ( data );
  }

  emitThisData ( data ) {
    this._emitter.emit ( data );
  }
  emitLoginLanguageChange ( data ) {
    this.langLoginChange.emit (data );
  }
  emitPreviosUrl(url){
    this.previousUrl=url;
  }
  emitReloadDygraph ( data ) {
    this.reloadDygraph.emit (data );
  }
  // emitgetselectedCompany ( data ) {
  //   this.selectedCompanyChange.emit(data );
  // }
  subscribeRefreshWizardEbill(data){
    this.refreshWizardDataSubjectEbill.next(data);
  }
  subscribeInterfaceStatus(data){
    this.checkInactiveInterFaceSubject.next(data);
  }
  companysearchnetcomId(data){
    this.companysearchNetcomIdStatus.next(data);
  }
  subscribeapiError(data){
    this.refreshApiErroralert.next(data);
  }
  getselectedCompany(data){
    this.selectedCompanyId.next(data);
  }

  selectedCompanyDeatils(data){
    console.log(data);
    this.selectCompanyId.next(data);
  }
  emitUserLanguageChange( data){
    this.userLangChange.next(data);
  }
  companysearchId(data){
    this.companysearchIdStatus.next(data);
  }
}