import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { NavigationService } from '../navigation/navigation.service';
import { CommonService } from '@app/core/services/common.service';
import * as jwt from "jsonwebtoken";

declare var $: any;

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html',
  styleUrls:['footer.component.scss']
})
export class FooterComponent implements OnInit {
  accountsUrl = environment.ACCOUNTS_URL;
  year = new Date().getFullYear();
  devOptionsRight:boolean = false;
  showDevOptions:boolean = false;
  showDecryptOptions:boolean = false;
  host: any;
  topic: any;
  path: any;
  constructor(private navService: NavigationService, private commonService: CommonService) {}

  ngOnInit() {
    this.devOptionsRight = environment.color ? true: false; 
  }

  openDevOption() {
    // $('#decodepopup').dialog("open");
    this.showDevOptions = !this.showDevOptions;
    this.showDecryptOptions = false;
  }

  openDecryptOption() {
    // $('#decodepopup').dialog("open");
    this.showDecryptOptions = !this.showDecryptOptions;
    this.showDevOptions = false;
  }

  clear() {
    this.host = "";
    this.topic = "";
    this.path = "";
  }

  checkKafka(){
    let formData = new FormData();
    formData.append("host",this.host);
    formData.append("topic", this.topic);
    formData.append("path",this.path);
    this.navService.checkKafka(formData).subscribe( data => {
      console.log(data);
      
    })
  }

  clearForm() {
    $('#encrypted').val('');
    $('#decrypted').val('');
  }

  decode() {
    let errorVal = '';
    let decryptedToken;
    if($('#encrypted').val()!='') {
      try{
        decryptedToken = this.commonService.decrypt($('#encrypted').val())
      }
      catch(Error){
        errorVal = "Not a valid Token";
      }
      if(errorVal==''){
        $('#decrypted').val(JSON.stringify(jwt.decode(decryptedToken), null, 2))
      }
      else{
        $('#encrypted').val(errorVal);
      }

    }
  }

}
