import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Subject, BehaviorSubject } from 'rxjs';

export type InternalStateType = {
  [key: string]: any
};
@Injectable({
  providedIn: 'root'
})
export class AppService {
  _state: InternalStateType = {};
  public subject = new Subject<any>();
  style;
  countryList:any = [];


  constructor(private httpClient: HttpClient) { }

  setLocalStorageFunctions() {
    Storage.prototype.getEncryptedItem = function (item) {
      if (item) {
        let encodedKey = btoa(item);
        let itemValue = localStorage.getItem(encodedKey);
        if (itemValue) {
          return atob(itemValue);
        }
      }
      return null;
    }
    Storage.prototype.setEncryptedItem = function (item, itemValue) {
      if (item && itemValue) {
        let encodedKey = btoa(item);
        let encodedValue = btoa(itemValue);
        localStorage.setItem(encodedKey, encodedValue);
        return true;
      }
      return false;
    }
    Storage.prototype.removeEncryptedItem = function (item) {
      if (item) {
        let encodedKey = btoa(item);
        localStorage.removeItem(encodedKey);
        return true;
      }
      return false;
    }
  }

  createAccessToken() {
    return this.httpClient.post(environment.API_END_POINT_MTC + '/createAccessToken', {}).pipe(map((response: Response) => response))
  }
  sendMessage(message: string) {
    // this.subject.next({ style: message });
    this.style = { style: message }
  }

  clearMessages() {
    this.subject.next();
  }


}
