import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonService } from '@app/shared/common/common.service';
import { FieldWrapper } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import * as moment from 'moment';
import { BillerCertificateService } from './billercertificate.service';
import { I18nService } from '@app/shared/i18n/i18n.service';
@Component({
  selector: 'formly-wrapper-panel',
  template: `
    <label class="sectorLbl" style="font-size:13px;">{{'ebill_Sector' |i18n}} *</label>
    <ng-select [(ngModel)]="selectedSector" [multiple]="true" class="companySelect" placeholder="{{'ebill_Sector' |i18n}}" (change)="addSector()" (blur)="validate()" (remove)=sectorChange($event) [ngClass]="{'requiredSection':isRequired}">
    <ng-option *ngFor="let sec of sectorData" [value]="sec.id">{{sec.name}}</ng-option>    
    </ng-select>
    <div *ngIf="isRequired" class="errorDiv mt-4"><small class="requiredSector">{{'ebill_Sector' |i18n}} {{'common_all_form_validation_message_required'|i18n}}</small></div> 
  `,
  styleUrls:['./billercertificate.component.scss']
})
export class certificatebillerPanelWrapperComponent extends FieldWrapper implements OnInit {
  sectorData=[];
  lanId;
  lngData={
    "en":"eng",
    "de":"ger",
    "fr":"fre",
    "it":"ita"
  }
  selectedSector:any;
  langChangeSubscription: any;
  isRequired:boolean=false;
  sectorSubScription:any;
  langLoading: boolean= true;
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;  
  constructor(private BillerCertificateService:BillerCertificateService,private commonService: CommonService,private i18nService: I18nService){
    super();
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => this.onLangChange(langObj));
  }
  ngOnInit(){
    // this.getSector();
    this.sectorSubScription= this.BillerCertificateService.setSector.subscribe(message => {
      if (message) {
        this.selectedSector = message;
      }  
    }); 
    this.BillerCertificateService.messageSector.subscribe(message=>{
      if(message.length==0){
        this.validate();
      }
    });   
  }
  onLangChange(langObj) {
    this.lanId = this.commonService.getCurrentLanguageCode();
    moment.locale(this.lanId);
    if (this.langLoading) {
      this.getSector();
    }
    setTimeout(() => {
      // this.getContactList();
    }, 1000);
    this.langLoading = false;
    langObj.isHandled = true;
  }
  ngOnDestroy() {
    this.langChangeSubscription ? this.langChangeSubscription.unsubscribe() : "";
    // this.sectorSubScription ? this.sectorSubScription.unsubscribe() : '';
  }
  sectorChange(eve){ 
    this.BillerCertificateService.setMessage(this.selectedSector); 
  }
  getSector(){
    this.lanId = this.commonService.getCurrentLanguageCode();
    if(this.sectorData.length==0){
      this.BillerCertificateService.getSectorsData()
      .subscribe(sectorItems=>{
        if(sectorItems['status']){
         sectorItems['data'].forEach(it=>{
          let d={id:it["id"],name:it['localizedData'][this.lngData[this.lanId]].name}
          this.sectorData.push(d);
         });      
        }    
      });
    }  
}
addSector(){
  if(this.selectedSector.length==0){
    this.isRequired=true;
  }
  else{
    this.isRequired=false;
  }
  this.BillerCertificateService.setMessage(this.selectedSector);
}
validate(){
  if(this.selectedSector){
    this.selectedSector.length==0?this.isRequired=true: this.isRequired=false;   
  }
  else{
    this.isRequired=true;
  }
}
}