import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from "@env/environment";
import { CommonService } from '../services/common.service';
import { map } from 'rxjs/operators';
import { I18nService } from '@app/shared/i18n/i18n.service';

@Injectable()
export class RoleResolveService implements Resolve<any> {

  constructor(private http: HttpClient,private commonService: CommonService,private router: Router,private i18nService: I18nService) { }
  resolve(): Observable<any> {
    let cookieExist = this.commonService.getCookie(environment.mtc_cookie);
    if (cookieExist == "" || !localStorage.getEncryptedItem('partyID')) {
      localStorage.clear();
      window.location.href = environment.LOGOUT_URL+encodeURIComponent(this.commonService.encrypt(window.location.href))
    }
    else{
      if( !localStorage.getEncryptedItem('userData') ){
        let formData = new FormData();
        formData.append('code','ADMIN')
        let objectUser = this.commonService.createObject(formData);
        let tokenUser = this.commonService.createToken(objectUser);
        let encryptedTokenUser = this.commonService.encrypt(tokenUser);
        let encrDataUser = new FormData();
        encrDataUser.append("encrToken", encryptedTokenUser);
        return this.http.post(environment.API_END_POINT_MTC+'/userProfile',encrDataUser)
        .pipe(map((dataFromApi) => {
          let user = dataFromApi['data'];
          // if( !user[0].hasPermission || !user[0].isConfirmedParty){
          //   localStorage.clear();
          //   window.location.href = environment.LOGIN_URL;
          // }
          let permissions = this.commonService.encrypt(String(user[0].permissions.Allow));
          localStorage.setEncryptedItem('permissions',permissions);
          // this.commonService.setCookie(environment.permissions,permissions,10000,environment.cookieDomain,'/');
          localStorage.setEncryptedItem('userData', JSON.stringify(user));
          localStorage.setEncryptedItem('accId',user[0].accId);
          localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty);
          localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty);
          localStorage.setEncryptedItem('hasPermission',user[0].hasPermission);
          
        }));
      }
    }
    
  }
}
