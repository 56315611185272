import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {


  constructor(private httpClient: HttpClient) {
   }
  //  getUserImg(accID){
  //   return this.httpClient.post(environment.API_END_POINT_MTC+`/getProfile`,accID)
  // }
  getCompanyDetails(encrData){
    return this.httpClient.post(environment.API_END_POINT_MTC +`/companyDetails`,encrData).pipe(map((response: Response) => response));
  }
  switchCompany(data) {
    return this.httpClient.post(environment.API_END_POINT_MTC+`/switchCompanyMtc`,data);
  }
  checkKafka(data) {
    return this.httpClient.post(environment.API_END_POINT_MTC+`/checkKafka`,data);
  }
}
