import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgIdleModule } from '@ng-idle/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/services';
import { AppService } from './app.service';
import { EmitService } from './ts/services/emit.service';
import { CommonService } from './shared/common/common.service';
import { AuthGuard } from './core/guards/auth.guard';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    NgIdleModule.forRoot(),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, AppService,EmitService,CommonService,AuthGuard,VersionCheckService],
  bootstrap: [AppComponent]
})
export class AppModule { }
