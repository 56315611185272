import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyManagementService {
  apiUrl: string;

  public searchData = {companyname: "",email: "", dateFrom: "", dateTo: "", thisMonth: false, mode: 'Live',network: ''};
  constructor(private httpClient: HttpClient) { 
    this.apiUrl = environment.API_END_POINT_MTC+'/gate2B';
    
  }

  verifyCompany(data){
    return this.httpClient.post(environment.API_END_POINT_MTC+`/verifyCompany`,data);
  }
  checkCompanyDuplicate(data){
    return this.httpClient.post(environment.API_END_POINT_MTC+`/companyNameVerification`,data);
  }
  mergeCompanies(data){
    return this.httpClient.post(environment.API_END_POINT_MTC+`/mergeCompany`,data);
  }
  filterDetails(data){
    return this.httpClient.post(environment.API_END_POINT_MTC+`/getPartyConfirmationPendingList`,data);
  }
  switchCompany(data) {
    return this.httpClient.post(environment.API_END_POINT_MTC+`/switchCompanyMtc`,data);
  }
  setSearchData(data) {
    this.searchData = {companyname: "",email: "", dateFrom: "", dateTo: "", thisMonth: false, mode: 'Live',network: ''};
    this.searchData = data;
  }
  getSearchData() {
    return this.searchData;
  }

  getNetworkList(){
    return this.httpClient.post(environment.API_END_POINT_MTC+'/getNetwork','');
  }

  getNetcomId(data){
    return this.httpClient.post(environment.API_END_POINT_MTC+`/getNetcomId`,data);
  }
  getFileGrabber(interfaceData){
    return this.httpClient.post(this.apiUrl+'/write-custom-log',interfaceData);
  }
   getService(){
  //   return this.httpClient.post(`http://localhost:5000/api/v1/gate2bAPI/getServiceDetails`,'');
  return this.httpClient.post(environment.API_END_POINT_MTC+`/getServiceDetails`,'');
   }
   setMtcLogin(data) {
    return this.httpClient.post(environment.API_END_POINT_ACCOUNT+`auth/setMtcLogin`,data);
  }
}
