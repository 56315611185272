import { EventEmitter,  Injectable,  Output} from '@angular/core';
import {  environment} from '@env/environment';
import {  HttpClient} from '@angular/common/http';
import {  map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommisionAgentService {
  @Output() messageCompany: EventEmitter < any > = new EventEmitter();
  @Output() setCompany: EventEmitter < any > = new EventEmitter();
  constructor(private httpClient: HttpClient) {}
  getCompanyList(data) {
    return this.httpClient.post(environment.API_END_POINT_MTC + `/getCompanyList`, data).pipe(map((response: Response) => response));
  }
  createRefrenceLink(data) {
    return this.httpClient.post(environment.API_END_POINT_MTC + `/createReferenceLink`, data).pipe(map((response: Response) => response));
  }
  setMessage(sector: any) {
    this.setCompany.emit(sector);
  }
  getCommissionBilledto(data) {
    return this.httpClient.post(environment.API_END_POINT_MTC + `/getCommissionBilledto`, data).pipe(map((response: Response) => response));
  }
  updateReferenceLink(data) {
    return this.httpClient.post(environment.API_END_POINT_MTC + `/updateReferenceLink`, data).pipe(map((response: Response) => response));
  }
  getAgentCompanyList(data){
    return this.httpClient.post(environment.API_END_POINT_MTC + `/getAgentCompanyList`, data).pipe(map((response: Response) => response));
  }
  createWhiteLabelConfig(data){
    return this.httpClient.post(environment.API_END_POINT_MTC + `/createWhiteLabelConfig`, data).pipe(map((response: Response) => response));

  }

  getCompanyLogoImages(data){
    return this.httpClient.post(environment.API_END_POINT_MTC + `/getCompanyLogoImages`, data).pipe(map( (response: Response) => response))
  }
}