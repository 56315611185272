import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { CommonService } from '@app/core/services/common.service';
import { environment } from '@env/environment';
import { UserService, LayoutService } from './core/services';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { I18nService } from './shared/i18n/i18n.service';
import { EmitService } from './ts/services/emit.service';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";

declare var $: any;
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit{
  countryList:any = [];
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  action = '';
  showCookieConsent:boolean = true;
  accountsUrl = environment.ACCOUNTS_URL;

  constructor(private appService : AppService,private router: Router,private commonService:CommonService,private userService: UserService,private idle: Idle,private layoutService:LayoutService,private emitService:EmitService,private versionCheckService:VersionCheckService){
    this.appService.setLocalStorageFunctions();
    // this.getCountryList();
    // this.setPermision();
    setInterval(() => {
      this.appService.createAccessToken().subscribe(data=>{ }); 
    },600000);
      let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.mtc_cookie)));
      // let cookieExist = {"cookieCreatedTime":"2022-06-21T07:07:13.000Z","userMail":"r.murali@iomarket.in","partyID":"71","userID":50,"accId":50,"netcomId":"41420-0000411494-85"};


    // let cookieExist = this.commonService.decrypt(decodeURIComponent('r629QRYi0NfhX8n25sJEih5S%2Fcuvt4b9TQi1EnyBHSu1%2BN%2BUqBPU1M46RteeZn%2BZDeA3OzirbI%2BAyCHhQYA7VqKrVEPDsDwZ8awZWUaM%2Frj4hx7oeBsNmx7XoT6%2BsxrPnz2rdleMXPgrb5Wkv0WqGZIV%2FFk31zqRl9KdGKXUQCgIIk1xkGFwPXjTERo%2Bo9%2FgcezYlgKMd8V4lopVqLmqckh5risNcHkm4ZF%2Bpy9I9EE%3D'));
    // let cookieExist = {"cookieCreatedTime":"2022-06-21T07:07:13.000Z","userMail":"r.murali@iomarket.in","partyID":"71","userID":50,"accId":50,"netcomId":"41420-0000411494-85"};
    if (localStorage.getEncryptedItem('loginCookieData')) {
      // this.setPermision();
       cookieExist =  JSON.parse(localStorage.getEncryptedItem('loginCookieData'));
      localStorage.getEncryptedItem('partyID') != cookieExist['partyID'] || localStorage.getEncryptedItem('userID') != cookieExist['userID'] ? localStorage.removeEncryptedItem('userData') : '';
      localStorage.setEncryptedItem('userMail',cookieExist['userMail']);
      localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
      localStorage.setEncryptedItem('userID',cookieExist['userID']);
      localStorage.setEncryptedItem('accId',cookieExist['accId']);
      localStorage.setEncryptedItem('netcomId',cookieExist['netcomId']);
      // localStorage.setEncryptedItem('isBexioCompany',cookieExist.hasOwnProperty('isBexioCompany')?cookieExist['isBexioCompany']:'false');
    }
    // localStorage.setEncryptedItem('netcomId','41420-0000411494-85');
     let p = String(["admin.company.*"]);
     let a = this.commonService.encrypt(p);
    //  localStorage.setEncryptedItem('permissions',a); 
  }
  ngOnInit() {
    this.versionCheckService.initVersionCheck('version.json');
  }
}
