import {  Component,  OnInit,  ViewChild,  ViewContainerRef} from '@angular/core';
import {  CommonService} from '@app/shared/common/common.service';
import {  FieldWrapper} from '@ngx-formly/core';
import {  BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import * as moment from 'moment';
import {  I18nService} from '@app/shared/i18n/i18n.service';
import {  CommisionAgentService} from '../commision-agent/commision-agent.service';
import { EmitService } from '@app/ts/services/emit.service';
@Component({
  selector: 'formly-wrapper-panel',
  template: `
    <label class="sectorLbl" style="font-size:13px;">{{'common_company_name' |i18n}} *</label>
    <ng-select appendTo="#content" [markFirst]="true" [(ngModel)]="selectedCompany" class="companySelect companylistdeatils" placeholder="{{'common_company_name' |i18n}}" (change)="addSector()" (remove)=sectorChange($event) [ngClass]="{'requiredSection':isRequired}">
    <ng-option *ngFor="let sec of companyList" [value]="sec.value">{{sec.label}}</ng-option>    
    </ng-select>
    <div *ngIf="isRequired" class="errorDiv mt-4"><small class="requiredSector">{{'common_company_name' |i18n}} {{'common_all_form_validation_message_required'|i18n}}</small></div> 
  `,
  styleUrls: ['../commision-agent/commision-agent.component.scss']
})
export class certificatePanelWrapperComponent extends FieldWrapper implements OnInit {
  companyList = [];
  lanId;
  lngData = {
    "en": "eng",
    "de": "ger",
    "fr": "fre",
    "it": "ita"
  }
  selectedCompany: any;
  langChangeSubscription: any;
  isRequired: boolean = false;
  sectorSubScription: any;
  langLoading: boolean = true;
  @ViewChild('fieldComponent', {
    read: ViewContainerRef
  }) fieldComponent: ViewContainerRef;
  selectedCompany1: any;
  constructor(private commonService: CommonService, private i18nService: I18nService, private commissionAgentService: CommisionAgentService,private emitService:EmitService,) {
    super();
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => this.onLangChange(langObj));
  }
  ngOnInit() {
    // this.getSector();
    this.sectorSubScription = this.commissionAgentService.setCompany.subscribe(message => {
      if (message) {
        this.selectedCompany = message;
      }
    });
    this.commissionAgentService.messageCompany.subscribe(message => {
      if (message != "") {
        this.validate();
      }
    });
  }
  onLangChange(langObj) {
    this.lanId = this.commonService.getCurrentLanguageCode();
    moment.locale(this.lanId);
    if (this.langLoading) {
      this.getSector();
    }
    setTimeout(() => {
      // this.getContactList();
    }, 1000);
    this.langLoading = false;
    langObj.isHandled = true;
  }
  ngOnDestroy() {
    this.langChangeSubscription ? this.langChangeSubscription.unsubscribe() : "";
    // this.sectorSubScription ? this.sectorSubScription.unsubscribe() : '';
  }
  sectorChange(eve) {
    this.commissionAgentService.setMessage(this.selectedCompany);
    this.emitService.selectedCompanyDeatils(this.selectedCompany);
  }
  getSector() {
    this.lanId = this.commonService.getCurrentLanguageCode();
    // if(this.companyList.length==0){
    this.commissionAgentService.getCompanyList('')
      .subscribe(sectorItems => {
        if (sectorItems['data'].length > 0) {
          this.companyList = [];  
          let companyList = sectorItems['data'];
          for (let i = 0; i < companyList.length; i++) {
            this.companyList.push({
              "value": companyList[i].partyid,
              "label": companyList[i].companyname
            });
            // if(companyList[i].partyid == localStorage.getEncryptedItem('partyID'))
            // this.companySelected = companyList[i].partyid;
          }
          this.selectedCompany =   this.companyList[0].value;
          this.emitService.selectedCompanyDeatils(this.selectedCompany);
        }

      });
    // }  
  }
  addSector() {
    if (this.selectedCompany == "") {
      this.isRequired = true;
    } else {
      this.isRequired = false;
    }
    this.commissionAgentService.setMessage(this.selectedCompany);
    this.emitService.selectedCompanyDeatils(this.selectedCompany);
  }
  validate() {
    if (this.selectedCompany) {
      this.selectedCompany == "" ? this.isRequired = true : this.isRequired = false;
    } else {
      this.isRequired = true;
    }
  }
}